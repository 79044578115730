<template>

  <div>
    <p style="font-family: Helvetica, Arial, sans-serif; opacity: 1;text-anchor: start;
    dominant-baseline: auto;
    font-size: 14px;
    font-weight: 900;
    fill: rgb(55, 61, 63); margin-left: 15px">欠账累计柱状图</p>
    <van-button type="primary" size="small" icon="plus" style="margin-left: 15px" @click="toDebtRanking">查看全部</van-button>
    <div id="chart">
      <apexchart type="bar" height="380" :options="chartOptions" :series="series"></apexchart>
    </div>
  </div>

</template>

<script>
import VueApexCharts from 'vue-apexcharts'
import * as dayjs from "dayjs";
import * as priceAdminService from "@/api/admin/price";
let quarterOfYear = require('dayjs/plugin/quarterOfYear')
dayjs.extend(quarterOfYear)

export default {
  components: {
    apexchart: VueApexCharts
  },
  data() {
    return {
      series: [],
      chartOptions: {
        chart: {
          type: 'bar',
          height: 380,
          toolbar: {
            show: false,
            autoSelected: 'pan'
          },
        },
        xaxis: {
          type: 'category',
          labels: {
            // formatter: function(val) {
            //   return "Q" + dayjs(val).quarter()
            // }
            formatter: function(val) {
              return val
            }
          },
          // group: {
          //   style: {
          //     fontSize: '10px',
          //     fontWeight: 700
          //   },
          //   groups: [
          //     { title: '2019', cols: 4 },
          //     { title: '2020', cols: 4 }
          //   ]
          // }
        },
        // title: {
        //   text: '欠账累计柱状图',
        // },
        tooltip: {
          x: {
            formatter: function(val) {
              return val

              // return "Q" + dayjs(val).quarter() + " " + dayjs(val).format("YYYY")
            }
          }
        },
      },
    }
  },
  created() {
    priceAdminService.getCreditColumn().then(res => {
      if (res.status === 200) {
        let resultData = {
          name: "欠款",
          data:  res.data
        }
        console.log(resultData)
        this.series = Array.of(resultData)
        console.log(this.series)
      }
    })
  },
  mounted() {
    // console.log("111")
    // let d = this.$refs.chart
    // // let d = document.querySelector(".chart")
    // console.log(d)
    // let a = d.scrollWidth
    // console.log(a)
    // d.parentElement.scrollTo(a, 0)
  },
  methods: {
    toHome() {
      let d = this.$refs.chart
      // let d = document.querySelector(".chart")
      console.log(d)
      let a = d.scrollWidth
      console.log(a)
      d.parentElement.scrollTo(a, 0)
      // this.$nextTick(() => {
      //   let d = document.querySelector(".chart")
      //   console.log(d)
      //   let a = d.offsetTop
      //   console.log(a)
      //   d.parentElement.scrollTo(0, a)
      // })
      // this.$router.push({path: '/index'})
    },
    toDebtRanking() {
      this.$router.push({path: '/statistics/debtRanking'})
    }
  }
}
</script>
<style lang="less">

.line-div::-webkit-scrollbar {
  display: none; /* Chrome Safari */
}

.line-div {
  scrollbar-width: none; /* firefox */
  -ms-overflow-style: none; /* IE 10+ */
}
</style>
